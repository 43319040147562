import React from "react";
import type { FC } from "react";
import { Chip, colors } from "@mui/material";
import { Theme } from "./../utils/util-theme";
import PoppyRedLogo from "../icons/poppy-red-logo.png";
import Logo from "./logo";
import { makeStyles } from "@mui/styles";
import { capitalizeFirstLetter } from "../utils/util-typography";
import { environment } from "../config";
import { Paragliding, RamenDining } from "@mui/icons-material";

const NEWLOGOANIMATION = false;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 50
  },
  /* old */
  logo: {
    color:
      theme.palette.mode === "dark"
        ? colors.common.white
        : theme.palette.primary.main,
    width: "100px",
    transformOrigin: "50% 50%",
    transition: "transform 1s linear",
    animationName: "$zoominoutsinglefeatured",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out"
  },
  "@keyframes zoominoutsinglefeatured": {
    "0%": {
      transform: "scale(1)",
      opacity: 0
    },
    "50%": {
      opacity: 1
    },
    "100%": {
      transform: "scale(3)"
    }
  },
  "@keyframes gelatine": {
    "0%": { transform: "scale(1, 1)" },
    "25%": { transform: "scale(0.9, 1.1)" },
    "50%": { transform: "scale(1.1, 0.9)" },
    "75%": { transform: "scale(0.95, 1.05)" }
  },
  /* new */
  redLogoContainer: {
    transformOrigin: "50% 50%",
    transition: "transform 0s linear",
    animationName: "$spin",
    animationDuration: "1.75s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out"
  },
  redLogo: { width: "100%", maxWidth: "150px" },
  stagingBadge: {
    fontWeight: 600,
    marginTop: 50,
    transition: "transform 1s linear",
    animationName: "$spin",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out"
  },
  "@keyframes spin": {
    "0%": {
      animationTimingFunction: "cubic-bezier(0.5856,0.0703,0.4143,0.9297)",
      transform: "rotate(0deg)",
      filter: "blur(0px)"
    },
    "50%": {
      filter: "blur(0.5px)"
    },
    "100%": {
      transform: "rotate(360deg)",
      filter: "blur(0px)"
    }
  }
}));

const SplashScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {NEWLOGOANIMATION ? (
        <div className={classes.redLogoContainer}>
          <img
            src={PoppyRedLogo}
            alt="Poppy RED logo"
            className={classes.redLogo}
          />
        </div>
      ) : (
        <Logo className={classes.logo} />
      )}
      {/* staging badge */}
      {environment !== "production" && (
        <Chip
          label={capitalizeFirstLetter(environment)}
          variant="filled"
          color={environment === "development" ? "info" : "secondary"}
          className={classes.stagingBadge}
          icon={
            environment === "development" ? <Paragliding /> : <RamenDining />
          }
        />
      )}
    </div>
  );
};

export default SplashScreen;
